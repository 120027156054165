<template>
  <div>
    <section>
      <BCarousel :autoplay="false" @change="change($event)">
        <BCarouselItem v-for="(video, i) in videos" :key="i">
          <section class="hero is-dark ">
            <div class="hero-body">
              <figure class="image is-16by9" style="padding: 16rem 0;">
                <video
                  ref="videos"
                  class="has-ratio"
                  :src="video.url"
                  :poster="video.poster"
                  preload="none"
                  @click="onClick(i)"
                  controls
                />
              </figure>
            </div>
            <div class="hero-foot"></div>
          </section>
        </BCarouselItem>
      </BCarousel>
    </section>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      videos: [
        {
          title: 'FIFA TV',
          url: 'https://golamateursfiles.blob.core.windows.net/amateurs-images/video1.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/golstats-01-video.jpg',
          playing: false,
        },
        {
          title: 'Mejor proveedor de la industria del futbol mundial',
          url: 'https://golamateursfiles.blob.core.windows.net/amateurs-images/video2.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/golstats-02-video.jpg',
          playing: false,
        },
        {
          title: 'Microsoft-GolStats',
          url: 'https://az740894.vo.msecnd.net/golstats-bets/golstats.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/microsoft-video.jpg',
          playing: false,
        },
        {
          title: 'Entrevista GolStats',
          url: 'https://golamateursfiles.blob.core.windows.net/amateurs-images/SAP.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/sap-video.jpg',
          playing: false,
        },
      ],
    };
  },
  mounted() {
    this.onClick(0);
  },
  async beforeDestroy() {
    await this.videos.forEach((p, i) => {
      this.$refs.videos[i].pause();
      this.videos[i].playing = false;
    });
  },
  methods: {
    onClick(index) {
      this.videos[index].playing = !this.videos[index].playing;
      this.videos[index].playing ? this.$refs.videos[index].play() : this.$refs.videos[index].pause();
    },
    async change(index) {
      await this.videos.forEach((p, i) => {
        this.$refs.videos[i].pause();
        this.videos[i].playing = false;
        this.$refs.videos[i].currentTime = 0;
      });
      this.videos[index].playing = true;
      this.$refs.videos[index].play();
    },
  },
};
</script>

<style scoped>
video {
  width: 94% !important;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
</style>
